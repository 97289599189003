<template>
  <div class="flex flex-col h-screen items-center justify-center overflow-hidden w-screen">
    <div class="bg-gray-100 border-b border-gray-200 font-semibold p-6 text-gray-900 text-lg w-full">
      {{ $t('header') }}
    </div>
    <div class="h-full overflow-auto p-6 scroll-shadow-y w-full" :class="{ 'mb-24': hasAgreement }">
      <div v-for="(term, index) in terms" :key="index" class="mb-6">
        <div class="font-bold h-8 mb-3 text-gray-700 text-xl w-full">{{ term.label }}</div>
        <div class="font-normal text-base text-gray-600 whitespace-pre-line">{{ term.body }}</div>
      </div>
      <div class="text-gray-500 text-xs">{{ lastUpdated }}</div>
      <cx-checkbox v-show="hasAgreement" :model-value="hasAgreed" @update:model-value="hasAgreed = !hasAgreed">
        {{ $t('checkboxText') }}
      </cx-checkbox>
    </div>
    <div v-show="hasAgreement" class="bg-white border-gray-200 border-t bottom-0 fixed h-24 left-0 p-6 w-full">
      <cx-button :disabled="!hasAgreed" class="w-32">{{ $t('buttonText') }}</cx-button>
    </div>
  </div>
</template>

<script>
import { CxButton, CxCheckbox } from '~/components/cx';
import { TERMS_LAST_UPDATED } from '~/support/constants';

export default {
  name: 'TermsAndConditions',

  components: {
    CxButton,
    CxCheckbox,
  },

  data() {
    return {
      hasAgreed: false,
      hasAgreement: false,
    };
  },

  computed: {
    lastUpdated() {
      return `${this.$t('lastUpdatedText')}: ${this.$formatting.tableDate(TERMS_LAST_UPDATED)}`;
    },

    terms() {
      return this.$t('legal');
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "header": "Terms and Conditions",
    "buttonText": "Get Started",
    "checkboxText": "I agree to the terms and conditions",
    "lastUpdatedText": "Last Updated"
  }
}
</i18n>
